var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { authInstance } from './firebase/base';
let initialFirebaseUserResolve;
const firebaseUserPromise = new Promise((resolve) => {
    initialFirebaseUserResolve = resolve;
});
let firebaseUser;
const makeFirebaseUserToSend = (user) => {
    if (!user) {
        return user;
    }
    return {
        // user returned by firebase is not serializable, so here we copy
        // all the fields that we use in the webapp code.
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous,
        displayName: user.displayName,
        photoURL: user.photoURL,
        phoneNumber: user.phoneNumber,
        metadata: user.metadata,
    };
};
const main = () => {
    // @ts-ignore
    if (!self.serviceWorker) {
        console.log('service worker executed in wrong context');
        // Sometimes if you make an importing or webpack configuration mistake,
        // you will include service worker in invalid context (e.g. inside the
        // window context). If you see the above message on the console you should
        // know that something you did with imports or webpack config is wrong.
        return;
    }
    console.log('service worker loaded in correct context');
    self.addEventListener('message', (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        console.log('service worker received a message');
        if (((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type) === 'getFirebaseUser') {
            event.data.port.postMessage({
                type: 'firebaseUser',
                firebaseUser: makeFirebaseUserToSend(firebaseUser || (yield firebaseUserPromise)),
            });
        }
        if (((_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.type) === 'getFirebaseIdToken') {
            const idToken = yield ((_c = (firebaseUser || (yield firebaseUserPromise))) === null || _c === void 0 ? void 0 : _c.getIdToken());
            event.data.port.postMessage({ type: 'firebaseIdToken', idToken });
        }
    }));
    authInstance.onAuthStateChanged((user) => {
        firebaseUser = user || undefined;
        initialFirebaseUserResolve(firebaseUser);
    });
    self.addEventListener('activate', (event) => {
        // @ts-ignore
        event.waitUntil(self.clients.claim());
    });
    // We could potentially intercept calls to the backend and add the firebase
    // token to them.
    /*
      self.addEventListener('fetch', (event) => {
        console.log('fetch event', event.request.url);
      });
    */
};
main();
